/**
 * Independent time operation tool to facilitate subsequent switch to dayjs
 */
import dayjs, { Dayjs } from 'dayjs';
import { isString } from './is';
import duration from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_DIVIDE = 'YYYY-MM-DD HH:mm';
export function formatToDateTime(
  date: string | number | dayjs.Dayjs | Date | null | undefined = undefined,
): string {
  if (!date && date !== 0) {
    return '';
  }
  if (date === 0) {
    return '-';
  }
  if (isString(date) && !/\D/.test(date)) {
    date = Number(date);
  }
  return dayjs(date).format(DATE_TIME_FORMAT);
}

export function formatToDate(
  date: string | number | dayjs.Dayjs | Date | null | undefined = undefined,
): string {
  if (!date && date !== 0) {
    return '';
  }
  if (isString(date) && !/\D/.test(date)) {
    date = Number(date);
  }
  return dayjs(date).format(DATE_FORMAT);
}

export function formatToDateDivide(
  date: string | number | dayjs.Dayjs | Date | null | undefined = undefined,
): string {
  if (!date && date !== 0) {
    return '';
  }
  if (isString(date) && !/\D/.test(date)) {
    date = Number(date);
  }
  return dayjs(date).format(DATE_DIVIDE);
}

export const dateUtil = dayjs;

// 判断指定日期是不是今天
export const checkToday = (current: Dayjs) => {
  if (!current) return false;
  const now = dayjs().endOf('day');
  return now.isSame(current, 'day');
};

// 日期组件-只能选择n天内时间
export const disabledRangeDate = (n: number, date: Dayjs) => {
  const now = dayjs().endOf('day');
  if (checkToday(date)) return false;
  if (n >= 0) {
    return (date && date < now) || date.diff(now, 'day') > n - 1;
  } else {
    return (date && date > now) || date.diff(now, 'day') <= n - 1;
  }
};

// 日期组件-只能选择当前时间之前的时刻
export const disabledAfterDate = (date: Dayjs) => {
  if (!date) return false;
  return !dayjs().isSameOrAfter(date, 'day');
};
// 只能选择当前时间之后的时刻
export const disabledBeforeDate = (date: Dayjs) => {
  if (!date) return false;
  return !dayjs().isSameOrBefore(date, 'day');
};

// 只能获取明天之后的时刻 不包含今天
export const disabledBeforeDateTomorrow = (date: dayjs.Dayjs) => {
  if (!date) return false;
  const tomorrow = dayjs().add(1, 'day').startOf('day'); // 获取明天的开始时间
  // 禁用今天及今天之前的日期
  return date.isBefore(tomorrow, 'day');
};

//
export const createRangeValidator = (number: number, date: dayjs.Dayjs) => {
  if (!date) return false;
  const tomorrow = dayjs().add(number, 'day').startOf('day'); // 获取明天的开始时间
  // 禁用今天及今天之前的日期
  return date.isBefore(tomorrow, 'day');
};

// 日期组件-显示时间
const range = (start: number, end: number) => {
  const result: number[] = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
// 禁用当前时间段之后的时间
export const disabledAfterTime = (current: Dayjs) => {
  current = current || dayjs();
  const h = current.hour();
  const m = current.minute();
  const s = current.second();
  return {
    disabledHours: () => range(h + 1, 24),
    disabledMinutes: (_h) => {
      if (h === _h) return range(m, 60);
      return [];
    },
    disabledSeconds: (_s) => {
      if (s === _s) return range(s, 60);
      return [];
    },
  };
};
// 禁用当前时间段之前的时间
export const disabledBeforeTime = (current: Dayjs) => {
  const h = current.hour();
  const m = current.minute();
  const s = current.second();
  return {
    disabledHours: () => range(0, h + 1),
    disabledMinutes: (_h) => {
      if (h === _h) return range(0, m);
      return [];
    },
    disabledSeconds: (_s) => {
      if (s === _s) return range(0, s);
      return [];
    },
  };
};

// 将时间戳转为 2000211 这种格式
export const timestampToCustomFormat = (timestamp, isMonth?: boolean, isYears?: boolean, isMoon?: boolean) => {
  // 创建一个新的 Date 对象，将时间戳作为参数传入
  const date = new Date(timestamp);

  // 获取年、月、日信息
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要加1，然后补齐两位
  const day = date.getDate().toString().padStart(2, '0'); // 补齐两位

  // 将年、月、日拼接成指定格式的字符串
  const formattedDate = `${year}${month}${day}`;

  if (isMonth) {
    return `${year}${month}`;
  }
  if (isYears) {
    return `${year}`;
  }
  if (isMoon) {
    return `${month}`
  }
  return formattedDate;
};

export const getYesterdayAndTodayTimestamps = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  // 将当前时间和昨天时间的时分秒毫秒部分都设置为 0
  today.setHours(0, 0, 0, 0);
  yesterday.setHours(0, 0, 0, 0);

  const timestampToday = today.getTime(); // 获取当前时间的毫秒级时间戳
  const timestampYesterday = yesterday.getTime(); // 获取昨天时间的毫秒级时间戳

  return {
    yesterday: timestampYesterday,
    today: timestampToday,
  };
};

export const getMonthTimestamps = () => {
  const currentDay = new Date();

  // 获取当前日期往前31天的时间戳
  const thirtyOneDaysAgo = new Date();
  thirtyOneDaysAgo.setDate(currentDay.getDate() - 30);
  const firstDayTimestamp = thirtyOneDaysAgo.getTime();
  const currentDayTimestamp = currentDay.getTime();

  return [firstDayTimestamp, currentDayTimestamp];
};


export const getMonthTime = (daysAgo = 30) => {
  const currentDay = new Date();
  // 获取今天的最后一秒，即 23:59:59.999
  const endOfDay = new Date(currentDay);
  endOfDay.setHours(23, 59, 59, 999);
  // 获取当前日期往前 'daysAgo' 天的时间戳
  const thirtyOneDaysAgo = new Date();
  thirtyOneDaysAgo.setDate(currentDay.getDate() - daysAgo);
  const firstDayTimestamp = thirtyOneDaysAgo.getTime();
  const endOfDayTimestamp = endOfDay.getTime();

  return [firstDayTimestamp, endOfDayTimestamp];
};

export const formatMonthYear = (input) => {
  // 将输入转换为字符串
  const str = input.toString();

  // 提取年份和月份
  const year = str.slice(0, 4);
  const month = str.slice(4);

  // 格式化输出
  const formatted = `${year}年${parseInt(month)}月`;

  return formatted;
}
// 示例用法
// const result = formatMonthYear(202407);
// console.log(result); // 输出：2024年7月


export const formatMonthDay = () => {
  //
  // 创建一个新的 Date 对象，该对象自动获取当前的日期和时间
  const currentDate = new Date();

  // 获取当前月份的天数
  const Year = currentDate.getFullYear(); // 获取当前年份
  const Month = currentDate.getMonth(); // 获取当前月份，注意月份从0开始，0表示一月，1表示二月，依此类推

  // 使用 Date 对象来获取下个月的第0天，即当前月的最后一天
  const lastDayOfMonth = new Date(Year, Month + 1, 0).getDate();

  return lastDayOfMonth
}
// 获得当前月天数


export const getMonthDatesArrayFromYearMonth = (yearMonth) => {
  const year = Math.floor(yearMonth / 100); // 获取年份部分
  const month = (yearMonth % 100) - 1; // 获取月份部分，注意要减去 1，因为 JavaScript 的月份是从 0 开始的

  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0); // 获取当月最后一天，0 表示前一个月的最后一天

  const daysInMonth = lastDayOfMonth.getDate(); // 获取当月天数

  const datesArray: string[] = [];
  for (let i = 1; i <= daysInMonth; i++) {
    datesArray.push(`${i}`);
  }

  return datesArray;
}
// 获得数组 [1,2,3,4,5,30,31] 当前月
