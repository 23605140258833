import { FetchDictMapResponseType } from '../api/sys/dict';

// 本地全局字典，与服务端请求的字典合并
export const LocalDict: FetchDictMapResponseType = {
  OrderNode: [
    { value: 20, name: '待派单', value1: '#3A87AD', value2: '#fff' },
    { value: 30, name: '待预约', value1: '#71749E', value2: '#fff' },
    { value: 40, name: '待上门', value1: '#3FABE9', value2: '#fff' },
    { value: 50, name: '待完工', value1: '#9EA552', value2: '#fff' },
    { value: 80, name: '待回访', value1: '#07A4B5', value2: '#fff' },
    { value: 100, name: '待入账', value1: '#468847', value2: '#fff' },
    { value: 110, name: '已入账', value1: '#468847', value2: '#fff' },
    { value: 90, name: '待退单', value1: '#A7DFF1', value2: '#2793D0' },
    { value: 120, name: '已退单', value1: '#999999', value2: '#fff' },
    { value: 130, name: '已取消', value1: '#B94A48', value2: '#fff' },
  ],
  // 操作人
  Operator: [
    { name: '客户', value: 1 },
    { name: '业务', value: 2 },
  ],
  // 审核类型
  ApproveType: [
    { name: '新网点入驻申请', value: 10 },
    { name: '老网点价格审核', value: 20 },
  ],
  // 价格类型
  CustomerPriceType: [
    { name: '自定义', value: 1 },
    { name: '标准价', value: 0 },
  ],
  // 客户账户状态
  AccountStatus: [
    { name: '欠款', value: 1 },
    { name: '正常', value: 2 },
  ],
  // 网点账户状态
  NetworkAccountStatus: [
    { name: '欠款', value: 1 },
    { name: '正常', value: 0 },
  ],
  // 到货情况,查询条件
  Arrival: [
    { name: '已到货', value: 1 },
    { name: '未到货', value: 0 },
  ],
  // 到货标识，表格使用
  ArrivalTable: [
    { name: '已到货', value: 1 },
    { name: '', value: 2 }, // 未到货，且无物流信息
    { name: '查物流', value: 3 },
  ],
  // 预约时间
  TimeType: [
    { name: '08:00~12:00', value: 10 },
    { name: '12:00~18:00', value: 20 },
    { name: '18:00~23:00', value: 30 },
  ],
  // 审核类型
  WarrantyApproveType: [
    { name: '保内⾃动审核', value: 10 },
    { name: '自费审核', value: 20 },
    { name: '质保未知审核', value: 30 },
  ],
  Parts: [
    { name: '待技术审核', alias: '已技术审核', value: 10 },
    { name: '待自购审核', alias: '已自购审核', value: 20 },
    { name: '待登记钉钉', alias: '已登记钉钉', value: 30 },
    { name: '待客户审核', alias: '已客户审核', value: 40 },
    { name: '仓库申请', alias: '已仓库申请', value: 50 },
    { name: '待发货', alias: '已发货', value: 60 },
    { name: '待签收', alias: '已签收', value: 70 },
    { name: '待返件', alias: '已返件', value: 80 },
    { name: '待返件签收', alias: '已返件签收', value: 90 },
    { name: '已完结', alias: '已完结', value: 100 },
    { name: '已驳回', alias: '已驳回', value: 110 },
    { name: '已取消', alias: '已取消', value: 120 },
  ],
  TypeApply: [
    { name: '商家寄发', value: 10 },
    { name: '商家向我购买', value: 20 },
  ],
};
