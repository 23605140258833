// axios配置  可自行根据项目进行更改，只需更改该文件即可，其他文件可以不动
// The axios configuration can be changed according to the project, just change the file, other files can be left unchanged

import type { AxiosResponse } from 'axios';
import { clone, throttle } from 'lodash-es';
import type { RequestOptions, Result } from '/#/axios';
import type { AxiosTransform, CreateAxiosOptions } from './axiosTransform';
import { VAxios } from './Axios';
import { checkStatus } from './checkStatus';
import { useGlobSetting } from '/@/hooks/setting';
import { useMessage } from '/@/hooks/web/useMessage';
import { RequestEnum, ResultEnum, ContentTypeEnum } from '/@/enums/httpEnum';
import { isString } from '/@/utils/is';
import { getToken } from '/@/utils/auth';
import { setObjToUrlParams, deepMerge } from '/@/utils';
import { useErrorLogStoreWithOut } from '/@/store/modules/errorLog';
import { useI18n } from '/@/hooks/web/useI18n';
import { joinTimestamp, formatRequestDate } from './helper';
import { useUserStoreWithOut } from '/@/store/modules/user';
import { PageEnum } from '/@/enums/pageEnum';
import { isEmpty } from 'lodash';
import { clearEmpty } from '../../index';
import Sign from '../../sign';
import { UserApi } from '/@/api/sys/user';
import { useRequestLoading } from './loading';
import jsonBig from 'json-bigint';
import { router } from '/@/router';
import { Encrypt } from '../../crypto';

const [open, close] = useRequestLoading();
const globSetting = useGlobSetting();
const urlPrefix = globSetting.urlPrefix;

const { createMessage, createErrorModal } = useMessage();
// Token失效,重新登录
const reLogin = throttle(
  () => {
    createMessage.error('登陆失效，请重新登陆');
    router.replace(PageEnum.BASE_LOGIN);
  },
  2000,
  { leading: true, trailing: false },
);
/**
 * @description: 数据处理，方便区分多种处理方式
 */
const transform: AxiosTransform = {
  /**
   * @description: 处理响应数据。如果数据不是预期格式，可直接抛出错误
   */
  transformResponseHook: (res: AxiosResponse<Result>, options: RequestOptions) => {
    const { t } = useI18n();
    const { isTransformResponse, isReturnNativeResponse, hideLoading } = options;
    if (!hideLoading) {
      close();
    }
    // 是否返回原生响应头 比如：需要获取响应头时使用该属性
    if (isReturnNativeResponse) {
      return res;
    }
    // 不进行任何处理，直接返回
    // 用于页面代码可能需要直接获取code，data，message这些信息时开启
    if (!isTransformResponse) {
      return res.data;
    }
    // 错误的时候返回

    const { data } = res;
    if (!data) {
      // return '[HTTP] Request has no return value';
      throw new Error(t('sys.api.apiRequestFailed'));
    }

    //  这里 code，result，message为 后台统一的字段，需要在 types.ts内修改为项目自己的接口返回格式
    const { code, data: result, msg: message } = data;
    // 对登录接口做特殊处理
    if (res.config.realUrl!.includes(UserApi.Login)) {
      if (data.code != 1) {
        return data;
      }
    }
    // 这里逻辑可以根据项目进行修改
    const hasSuccess = data && Reflect.has(data, 'code') && code === ResultEnum.SUCCESS;
    if (hasSuccess) {
      return result;
    }
    // 在此处根据自己项目的实际情况对不同的code执行不同的操作
    // 如果不希望中断当前请求，请return数据，否则直接抛出异常即可
    let timeoutMsg = '';
    switch (code) {
      case ResultEnum.TIMEOUT:
        timeoutMsg = t('sys.api.timeoutMessage');
        const userStore = useUserStoreWithOut();
        userStore.setToken(undefined);
        userStore.logout(true);
        break;
      case 400:
        timeoutMsg = message || '错误请求';
        break;
      case 401:
        reLogin();
        options.errorMessageMode = 'none';
        break;
      case 403:
        timeoutMsg = message || '非法token，拒绝访问';
        break;
      case 404:
        timeoutMsg = message || '请求错误，资源找不到了';
        break;
      case 408:
        timeoutMsg = message || '请求超时';
        break;
      case 500:
        timeoutMsg = message || '服务器错误';
        break;
      default:
        timeoutMsg = message || '连接错误';
    }
    // errorMessageMode=‘modal’的时候会显示modal错误弹窗，而不是消息提示，用于一些比较重要的错误
    // errorMessageMode='none' 一般是调用时明确表示不希望自动弹出错误提示
    if (options.errorMessageMode === 'modal') {
      createErrorModal({ title: t('sys.api.errorTip'), content: timeoutMsg });
    } else if (options.errorMessageMode === 'message') {
      createMessage.error(timeoutMsg);
    }

    throw new Error(timeoutMsg || t('sys.api.apiRequestFailed'));
  },
  // 请求失败处理
  requestCatchHook: (e, options) => {
    if (!options.hideLoading) {
      close();
    }
    return Promise.reject(e);
  },

  // 请求之前处理config
  beforeRequestHook: (config, options) => {
    const {
      apiUrl,
      joinPrefix,
      joinParamsToUrl,
      formatDate,
      joinTime = true,
      urlPrefix,
      hideLoading,
    } = options;

    config.realUrl = config.url;

    if (joinPrefix && !options.local) {
      config.url = `${urlPrefix}${config.url}`;
    }

    if (apiUrl && isString(apiUrl)) {
      config.url = `${apiUrl}${config.url}`;
    }
    let params = config.params || false;
    let data = config.data || false;
    formatDate && data && !isString(data) && formatRequestDate(data);
    formatDate && params && !isString(params) && formatRequestDate(params);
    // 清除参数中的空值，防止请求参数为空导致请求失败
    if (!isEmpty(params)) {
      params = clearEmpty(params);
    }
    if (!isEmpty(data)) {
      data = clearEmpty(data);
    }
    if (config.method?.toUpperCase() === RequestEnum.GET) {
      if (!isString(params)) {
        // 给 get 请求加上时间戳参数，避免从缓存中拿数据。
        config.params = Object.assign(params || {}, joinTimestamp(joinTime, false));
      } else {
        // 兼容restful风格
        config.url = config.url + params + `${joinTimestamp(joinTime, true)}`;
        config.params = undefined;
      }
    } else {
      if (!isString(params)) {
        if (
          Reflect.has(config, 'data') &&
          config.data &&
          (Object.keys(config.data).length > 0 || config.data instanceof FormData)
        ) {
          config.data = data;
          config.params = params;
        } else {
          // 非GET请求如果没有提供data，则将params视为data
          config.data = params;
          config.params = undefined;
        }
        if (joinParamsToUrl) {
          config.url = setObjToUrlParams(
            config.url as string,
            Object.assign({}, config.params, config.data),
          );
        }
      } else {
        // 兼容restful风格
        config.url = config.url + params;
        config.params = undefined;
      }
    }
    let message = {};
    if (
      ['GET', 'DELETE'].includes(config.method?.toLocaleUpperCase() as string) ||
      Object.prototype.toString.call(data) === '[object FormData]'
    ) {
      if (!isEmpty(params)) {
        message = params;
      } else {
        const [, ...realUrlArray] = config.url?.substring(1).split('/') as string[];
        const reg = new RegExp(urlPrefix || '');
        const PATH = ('/' + realUrlArray?.join('/')).replace(reg, '');
        message = {
          PATH,
        };
      }
    } else {
      message = data;
    }
    const sign = Sign(message);
    config.headers = {
      ...config.headers,
      sign,
    };
    if (!hideLoading) {
      open();
    }
    // 接口加密
    if (import.meta.env.VITE_API_ENCRYPT === 'true' && !options.decrypt) {
      const realUrl = config.realUrl!.slice(1);
      config.url = config.url!.replace(realUrl, Encrypt(`${realUrl};${+new Date()}`));
    }
    return config;
  },

  /**
   * @description: 请求拦截器处理
   */
  requestInterceptors: (config, options) => {
    if ((config as Recordable)?.requestOptions?.withToken) {
      const token = getToken();
      if (token) {
        // jwt token
        (config as Recordable).headers.Authorization = options.authenticationScheme
          ? `${options.authenticationScheme} ${token}`
          : token;
      } else {
        reLogin();
        return Promise.reject('登陆失效，请重新登陆');
      }
    } else {
      // 不需要鉴权的请求地址
      config.headers = {
        ...config.headers,
        Authorization: `Basic ${import.meta.env.VITE_BASE_AUTHORIZATION}`,
      };
    }
    return config;
  },

  /**
   * @description: 响应拦截器处理
   */
  responseInterceptors: (res: AxiosResponse<any>, _options: RequestOptions) => {
    return res;
  },

  /**
   * @description: 响应错误处理
   */
  responseInterceptorsCatch: (
    _axiosInstance: AxiosResponse,
    error: any,
    _options: RequestOptions,
  ) => {
    const { t } = useI18n();
    const errorLogStore = useErrorLogStoreWithOut();
    errorLogStore.addAjaxErrorInfo(error);
    const { response, code, message, config } = error || {};
    const errorMessageMode = config?.requestOptions?.errorMessageMode || 'none';
    const msg: string = response?.data?.msg ?? message;
    const err: string = error?.toString?.() ?? '';
    let errMessage = '';

    try {
      if (code === 'ECONNABORTED' && message.indexOf('timeout') !== -1) {
        errMessage = t('sys.api.apiTimeoutMessage');
      }
      if (err?.includes('Network Error')) {
        errMessage = t('sys.api.networkExceptionMsg');
      }

      if (errMessage) {
        if (errorMessageMode === 'modal') {
          createErrorModal({ title: t('sys.api.errorTip'), content: errMessage });
        } else if (errorMessageMode === 'message') {
          createMessage.error(errMessage);
        }
        return Promise.reject(error);
      }
    } catch (error) {
      throw new Error(error as unknown as string);
    }
    checkStatus(error?.response?.status, response?.data?.code === 401 ? '' : msg, errorMessageMode);
    return Promise.reject(msg);
  },
};

function createAxios(opt?: Partial<CreateAxiosOptions>) {
  return new VAxios(
    // 深度合并
    deepMerge(
      {
        // See https://developer.mozilla.org/en-US/docs/Web/HTTP/Authentication#authentication_schemes
        // authentication schemes，e.g: Bearer
        authenticationScheme: 'Bearer',
        timeout: 20000,
        headers: { 'Content-Type': ContentTypeEnum.JSON },
        // 如果是form-data格式
        // headers: { 'Content-Type': ContentTypeEnum.FORM_URLENCODED },
        // 数据处理方式
        transform: clone(transform),
        // transformResponse 允许自定义原始的响应数据（字符串）
        transformResponse: [
          function (data) {
            try {
              // 如果转换成功则返回转换的数据结果
              return jsonBig.parse(data);
            } catch (err) {
              // 如果转换失败，则包装为统一数据格式并返回
              return {
                data,
              };
            }
          },
        ],
        // 配置项，下面的选项都可以在独立的接口请求中覆盖
        requestOptions: {
          // 默认将prefix 添加到url
          joinPrefix: true,
          // 是否返回原生响应头 比如：需要获取响应头时使用该属性
          isReturnNativeResponse: false,
          // 需要对返回数据进行处理
          isTransformResponse: true,
          // post请求的时候添加参数到url
          joinParamsToUrl: false,
          // 格式化提交参数时间
          formatDate: true,
          // 消息提示类型
          errorMessageMode: 'message',
          // 接口地址
          apiUrl: globSetting.apiUrl,
          // apiUrl: '/cmf-api',
          // 接口拼接地址
          urlPrefix: urlPrefix,
          //  是否加入时间戳
          joinTime: false,
          // 忽略重复请求
          ignoreCancelToken: true,
          // 是否携带token
          withToken: true,
          retryRequest: {
            isOpenRetry: false,
            count: 1,
            waitTime: 100,
          },
        },
      },
      opt || {},
    ),
  );
}

// 默认请求，不需要网关
export const defHttp = createAxios();

// md网关HTTP
export const mdHttp = createAxios({
  requestOptions: {
    urlPrefix: '/md',
  },
});

// fi网关HTTP
export const fiHttp = createAxios({
  requestOptions: {
    urlPrefix: '/fi',
  },
});

// sd网关HTTP
export const sdHttp = createAxios({
  requestOptions: {
    urlPrefix: '/sd',
  },
});

// sd网关HTTP
export const imHttp = createAxios({
  requestOptions: {
    urlPrefix: '/im',
  },
});

// rpt网关HTTP
export const rptHttp = createAxios({
  requestOptions: {
    urlPrefix: '/rpt',
  },
});

// sys网关HTTP
export const sysHttp = createAxios({
  requestOptions: {
    urlPrefix: '/sys',
  },
});

// log网关HTTP
export const logHttp = createAxios({
  requestOptions: {
    urlPrefix: '/log',
  },
});

// aivoice网关HTTP
export const aivoiceHttp = createAxios({
  requestOptions: {
    urlPrefix: '/aivoice',
  },
});
export const envaivoiceHttp = createAxios({
  requestOptions: {
    urlPrefix: '/envaivoice',
  },
});

// dingtalk网关HTTP
export const dingtalkHttp = createAxios({
  requestOptions: {
    urlPrefix: '/dingtalk',
  },
});

// 无需token授权的接口（登录使用）
export const otherHttp = createAxios({
  requestOptions: {
    withToken: false,
    urlPrefix: '/auth',
  },
});

// 无需token授权的接口（登录使用） 开发环境
export const otherDevHttp = createAxios({
  timeout: 9999999999,
  requestOptions: {
    withToken: false,
    apiUrl: '/login-dev',
    urlPrefix: '/auth',
  },
});

export const sysDevHttp = createAxios({
  timeout: 9999999999,
  requestOptions: {
    apiUrl: '/login-dev',
    urlPrefix: '/sys',
  },
});

// 三方呼叫系统
export const callHttp = createAxios({
  requestOptions: {
    withToken: false,
    urlPrefix: 'https://cti.kklgo.com/admin/sdk/index',
    apiUrl: '',
  },
  transform: deepMerge(clone(transform), {
    requestInterceptors(config) {
      delete config.headers.sign;
      return config;
    },
    transformResponseHook(res, options) {
      const { hideLoading } = options;
      if (!hideLoading) {
        close();
      }
      if ((res.data as any).result === 0) {
        createMessage.error(res.data.msg);
        throw new Error(res.data.msg);
      }
      return res.data.data;
    },
  }),
});

// 开发环境
const createDevOption = (apiUrl: string) => {
  return {
    timeout: 9999999999,
    requestOptions: {
      local: true, //会自动删除网关路径
      apiUrl,
    },
  };
};

export const qwerHttp = createAxios(createDevOption('/qwer-dev'));
