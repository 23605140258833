/*
 * @Author: jeardwang jeardwang@sina.com
 * @Date: 2023-09-01 15:16:18
 * @LastEditors: jeardwang jeardwang@sina.com
 * @LastEditTime: 2023-10-18 10:40:35
 * @FilePath: /qx-phone-lib/lib/websocket.ts
 * @Description: websocket
 */

import { Message, Result } from './core';
import { Emit, Subscribe } from './emit';
import { createMessage } from './message';

export class WebSocketClient {
  private socket: WebSocket | undefined;
  private url: string;
  private channel: string | undefined;
  private key: string | undefined;
  private state: 'connected' | 'disconnected' | 'reconnecting' = 'disconnected';
  private onMessage: ((message: Message) => void) | undefined;
  private timer: NodeJS.Timeout | undefined;

  constructor(url: string, callbacks?: ((message: Message) => void) | undefined) {
    this.url = url;
    this.onMessage = callbacks;
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        this.send({
          ...createMessage(),
          event: 'visibilityChange',
          payload: {
            hidden: document.hidden,
          },
        });
      }
    });
  }

  public connect(url?: string): Promise<void> {
    if (url === undefined) {
      url = this.url;
    }
    this.state === 'reconnecting';
    return new Promise((resolve, reject) => {
      this.socket = new WebSocket(url!);
      this.socket.onclose = () => {
        if (this.onMessage) {
          this.onMessage({
            payload: null,
            event: 'close',
          });
        }
        this.state = 'disconnected';
        // setTimeout(() => {
        //   this.connect();
        // }, 1000);
      };
      this.socket.onopen = () => {
        this.state = 'connected';
        resolve();
        if (this.onMessage) {
          this.receive(this.onMessage);
        }
        if (this.timer) {
          clearInterval(this.timer);
        }
        // 发送心跳
        // this.timer = setInterval(() => {
        //   this.send({
        //     ...createMessage(),
        //     event: 'ping',
        //   });
        // }, 1000 * 15);
      };
      this.socket.onerror = () => {
        reject();
      };
    });
  }

  public close(): void {
    if (!this.socket) {
      throw new Error('No socket connection.');
    }
    this.socket.close();
  }

  public isOpen(): boolean {
    return this.socket?.readyState === WebSocket.OPEN;
  }

  public on(): void {
    this.socket!.onopen = (data) => {
      console.log('onopen:', data);
    };
  }

  public send(data: Message): void {
    if (!this.socket) {
      throw new Error('No socket connection.');
    }
    data.channel = this.channel;
    data.client = this.key;
    this.socket.send(JSON.stringify(data));
  }

  public async sendAsync(data: Message): Promise<Result<null | Message>> {
    if (!this.socket) {
      throw new Error('No socket connection.');
    }
    data.channel = this.channel;
    data.client = this.key;
    this.socket!.send(JSON.stringify(data));
    let response: Message | undefined;
    Subscribe<Message>(data.messageId!, (message: Message) => {
      response = message;
    });
    return new Promise((resolve, reject) => {
      let count = 0;
      const timer = setInterval(() => {
        count++;
        if (response !== undefined) {
          clearInterval(timer);
          resolve({ success: true, message: 'success', data: response });
        } else if (count > 100) {
          resolve({ success: false, message: 'timeout', data: null });
          clearInterval(timer);
          reject('timeout');
        }
      }, 100);
    });
  }

  public receive(callback: (data: Message) => void): void {
    this.onMessage = callback;
    if (!this.socket) {
      throw new Error('No socket connection.');
    }
    this.socket.onmessage = (message: MessageEvent) => {
      const data = JSON.parse(message.data) as Message & { sourceMessageId?: string };
      if (data.sourceMessageId) {
        Emit(data.sourceMessageId!, data);
      }
      if (data.channel) {
        this.channel = data.channel;
      }
      if (data.client) {
        this.key = data.client;
      }
      callback(data);
    };
  }
}
